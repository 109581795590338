export const footerSections = {
  pupils: {
    title: 'Pupils',
    links: [
      {
        text: 'Learn online',
        href: 'https://www.thenational.academy/pupils/years',
      },
    ],
  },
  teachers: {
    title: 'Teachers',
    links: [
      {
        text: 'EYFS',
        href: 'https://www.thenational.academy/teachers/key-stages/early-years-foundation-stage/subjects',
      },
      {
        text: 'Specialist',
        href: 'https://www.thenational.academy/teachers/specialist/subjects',
      },
      {
        text: 'Key stage 1',
        href: 'https://www.thenational.academy/teachers/key-stages/ks1/subjects',
      },
      {
        text: 'Key stage 2',
        href: 'https://www.thenational.academy/teachers/key-stages/ks2/subjects',
      },
      {
        text: 'Key stage 3',
        href: 'https://www.thenational.academy/teachers/key-stages/ks3/subjects',
      },
      {
        text: 'Key stage 4',
        href: 'https://www.thenational.academy/teachers/key-stages/ks4/subjects',
      },

      {
        text: 'Curriculum plans',
        href: 'https://www.thenational.academy/teachers/curriculum',
      },
      {
        text: 'Plan a lesson',
        href: 'https://www.thenational.academy/lesson-planning',
      },
      {
        text: 'Support your team',
        href: 'https://www.thenational.academy/support-your-team',
      },
    ],
  },
  oak: {
    title: 'Oak',
    links: [
      { text: 'Home', href: 'https://www.thenational.academy/' },
      {
        text: 'About us',
        href: 'https://www.thenational.academy/about-us/who-we-are',
      },
      {
        text: 'Careers',
        href: 'https://jobs.thenational.academy',
        icon: 'external',
        ariaLabel: 'Careers (opens in a new tab)',
      },
      {
        text: 'Contact us',
        href: 'https://www.thenational.academy/contact-us',
      },
      {
        text: 'Help',
        type: 'page',
        href: 'https://support.thenational.academy/',
        icon: 'external',
        ariaLabel: 'Help (opens in a new tab)',
      },
      { text: 'Blog', href: 'https://www.thenational.academy/blog' },
      { text: 'Webinars', href: 'https://www.thenational.academy/webinars' },
      {
        text: 'Status',
        href: 'https://status.thenational.academy',
        icon: 'external',
        ariaLabel: 'Status (opens in a new tab)',
      },
    ],
  },

  legal: {
    title: 'Legal',
    links: [
      {
        text: 'Terms & conditions',
        href: 'https://www.thenational.academy/legal/terms-and-conditions',
      },
      {
        text: 'Privacy policy',
        href: 'https://www.thenational.academy/legal/privacy-policy',
      },
      {
        text: 'Cookie policy',
        href: 'https://www.thenational.academy/legal/cookie-policy',
      },
      // { text: 'Manage cookie settings', type: 'consent-manager-toggle' },

      {
        text: 'Copyright notice',
        href: 'https://www.thenational.academy/legal/copyright-notice',
      },
      {
        text: 'Accessibility statement',
        href: 'https://www.thenational.academy/legal/accessibility-statement',
      },
      {
        text: 'Safeguarding statement',
        href: 'https://www.thenational.academy/legal/safeguarding-statement',
      },
      {
        text: 'Physical activity disclaimer',
        href: 'https://www.thenational.academy/legal/physical-activity-disclaimer',
      },
      {
        text: 'Complaints',
        href: 'https://www.thenational.academy/legal/complaints',
      },
      {
        text: 'Freedom of information requests',
        href: 'https://www.thenational.academy/legal/freedom-of-information-requests',
      },
    ],
  },
};
